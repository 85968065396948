<template>
    <v-dialog v-model="dialog" persistent max-width="1200px">
        <v-card>
            <v-card-title>
                <span class="headline">{{  titleFiles }}</span>
            </v-card-title>
            <v-card-text>
                <v-simple-table dense border="left" fixed-header>
                    <template v-slot:default>
                        <thead>
                            <th class="text-left">Folio documento</th>
                            <th class="text-left">Tipo archivo</th>
                            <th class="text-left">Nombre</th>
                            <th class="text-left">Archivo</th>
                        </thead>
                        <tbody>
                            <tr v-for="(item, index) in lstFiles" :key="index">
                                <td v-text="item.folio"></td>
                                <td v-text="item.type"></td>
                                <td v-text="item.originalName"></td>
                                <td>
                                    <!-- Image Display with Download Option -->
                                    <div v-if="item.typeFile === 'image'">
                                        <v-img :src="item.url" max-height="150" max-width="250" contain>
                                            <v-tooltip right color="info">
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn :href="item.url" download @click.prevent="downloadFile(item)"
                                                        v-bind="attrs" v-on="on" dark
                                                        elevation="2" fab x-small color="blue darken-4 text-white">
                                                        <v-icon small class="text-white">fas fa-download</v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>Descargar imagen</span>
                                            </v-tooltip>
                                            <v-tooltip right color="info">
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn :href="item.url" download @click.prevent="openZoom(item)" dark
                                                        v-bind="attrs" v-on="on"
                                                        class="ml-2" elevation="2" fab x-small color="blue darken-4 text-white">
                                                        <v-icon small class="text-white">fa-solid fa-magnifying-glass-plus</v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>Ver imagen</span>
                                            </v-tooltip>
                                        </v-img>
                                    </div>
                                    <!-- PDF Display Option -->
                                    <v-btn class="blue darken-4 text-white" small dark v-else-if="item.typeFile === 'pdf'" @click.prevent="previewPDF(item)">
                                        <v-icon left small>fas fa-file-pdf</v-icon> Ver PDF
                                    </v-btn>
                                    <a v-else :href="item.url" target="_blank" class="file-link">Ver archivo</a>
                                </td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
                <PreviewPDF :pdfSource="pdfSource" :pdfBlob="pdfBlob"
                    title-pdf="ARCHIVO" :handlePdfModal="handlePdfModal"
                    :dialog="showPdf" v-if="pdfSource && showPdf">
                </PreviewPDF>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="handleDialogFiles">Cerrar</v-btn>
            </v-card-actions>
        </v-card>
        <!-- Image Preview Dialog -->
        <v-dialog v-model="dialogZoom" max-width="800px" v-if="urlZoom != null && dialogZoom">
            <v-card>
                <v-card-title class="headline font-weight-bold text-h5 primary darken-1 white--text">Previsualizar imagen</v-card-title>
                <v-card-text>
                    <v-img :src="urlZoom" max-height="600" max-width="720" contain></v-img>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="green darken-3 white--text" @click.prevent="dialogZoom = false">
                        <v-icon left>fas fa-times</v-icon> Cerrar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-dialog>
</template>
<script>
import FileDataService from '@/service/FileDataService';
import PreviewPDF from './PreviewPDF.vue';

export default {
    name: 'ViewFilesTable',
    props: {
        titleFiles: { type: String, default: "Archivos" },
        dialog: { type: Boolean, default: false },
        handleDialogFiles: { type: Function },
        lstFiles: { type: Array }
    },
    components: { PreviewPDF },
    data() {
        return {
            pdfSource: null,
            pdfBlob: null,
            showPdf: false,
            dialogZoom: false,
            urlZoom: null
        }
    },
    methods: {
        downloadFile({ path, name, originalName }) {
            FileDataService.download({ path }).then(response => {
                const blob = new Blob([response.data], { type: 'application/image' })
                const url = URL.createObjectURL(blob)
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', originalName)
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
                URL.revokeObjectURL(url)
            })
        },
        openZoom({ url}){
            this.urlZoom = url
            this.dialogZoom = true
        },
        previewPDF({ path }) {
            FileDataService.download({ path }).then(response => {
                this.pdfBlob = new Blob([response.data], { type: 'application/pdf' })
                this.pdfSource = URL.createObjectURL(this.pdfBlob)
                this.showPdf = true
            }).catch(err => {
                this.$swal.fire({ title: 'Error', text: 'No se pudo procesar la petición, ' + err, icon: 'error', showConfirmButton: false, timer: 2000 })
            });
        },
        handlePdfModal() {
            this.cleanupPdf()
            this.showPdf = false
        },
        cleanupPdf() {
            if (this.pdfSource) {
                URL.revokeObjectURL(this.pdfSource);
                this.pdfSource = null;
            }
            this.pdfBlob = null;
        }
    }
}
</script>