import { http, authHeader } from '@/http-commons';

class TransactionCategoryService {

    create( data ){
        return http.post('api/v1/transactionCategory/create', data, { headers : authHeader() });
    }
    show( transactionCategoryId ){
        return http.get(`api/v1/transactionCategory/findOne/${transactionCategoryId}`, { headers : authHeader() });
    }
    findAll(){
        return http.get("api/v1/transactionCategory/all", {headers: authHeader() });
    }
    table( params ){
        return http.get('api/v1/transactionCategory/table', { params, headers : authHeader() });
    }
    update( data ){
        return http.put(`api/v1/transactionCategory/update/${data.id}`, data, { headers : authHeader() });
    }
    delete( transactionCategoryId ){
        return http.delete(`api/v1/transactionCategory/delete/${transactionCategoryId}`, { headers : authHeader() });
    }
}

export default new TransactionCategoryService();