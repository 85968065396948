<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" persistent transition="scale-transition" max-width="1200px">
            <v-card class="elevation-2">
                <ValidationObserver ref="obstransaction" v-slot="{invalid, validated}">
                    <v-form name="form" @submit.prevent="transactionId ? update() : saveTransaction()">
                        <v-toolbar tile dark color="primary">
                            <v-btn icon dark @click.native="handleDialog">
                                <v-icon>fas fa-times-circle</v-icon>
                            </v-btn>
                            <v-toolbar-title>{{  title }}</v-toolbar-title>
                            <v-spacer></v-spacer>
                            <v-toolbar-items>
                                <v-btn class="danger" text type="submit" :disabled="invalid || !validated">
                                <v-icon class="ma-2" dense large>fas fa-save</v-icon>Guardar transaccion</v-btn>
                            </v-toolbar-items>
                        </v-toolbar>
                        <v-card-text>
                            <v-row>
                                <v-col cols="12" sm="12" md="4">
                                    <SingleCalendar refElement="dateTransaction" :input-date.sync="transaction.registerDate" title="Fecha de transaccion" :requiredInput="true"/>
                                </v-col>
                                 <v-col cols="12" sm="12" md="4" class="mt-3">
                                    <ValidationProvider rules="required" v-slot="{ errors, valid }" :immediate="true">
                                        <v-autocomplete :items="transactionType" item-text="text" item-value="value"
                                        label="Tipo de transaccion*" v-model="transaction.transactionType" required clearable prepend-icon="fas fa-briefcase"
                                        :error-messages="errors" :success="valid">
                                        </v-autocomplete>
                                    </ValidationProvider>
                                </v-col>
                                <v-col cols="12" sm="12" md="4" class="mt-3">
                                    <ValidationProvider rules="required" v-slot="{ errors, valid }" :immediate="true">
                                        <v-autocomplete :items="transactionCategory" item-text="name" item-value="id"
                                        label="Tipo de transaccion*" v-model="transaction.transactionCategoryId" required clearable prepend-icon="fas fa-briefcase"
                                        :error-messages="errors" :success="valid">
                                        </v-autocomplete>
                                    </ValidationProvider>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" sm="12" md="4">
                                    <ValidationProvider rules="required|decimal|min_value:0.01" v-slot="{ errors, valid }" :immediate="true">
                                        <v-text-field v-model="transaction.amount" type="number" class="mt-3 font-weight-black text-h6"
                                            label="Monto*" hint="Monto de la transaccion"                                
                                            persistent-hint required min="0.01" placeholder="0.00" prepend-icon="fas fa-dollar-sign" 
                                            :error-messages="errors" :success="valid">
                                        </v-text-field>
                                    </ValidationProvider>
                                </v-col>
                                <v-col cols="12" sm="12" md="3" >
                                    <v-checkbox v-model="transaction.isCashRegister" label="Fue efectuado en caja?" color="success" hide-details></v-checkbox>
                                </v-col>
                                <v-col cols="12" sm="12" md="5" v-if="transaction.isCashRegister">
                                    <ValidationProvider rules="required" v-slot="{ errors, valid}" :immediate="true">
                                        <v-autocomplete :items="lstCashRegister" item-text="opened" item-value="id" v-model="transaction.cashRegisterId"
                                        label="Caja registradora" required  prepend-inner-icon="fas fa-layer-group" chips small-chips
                                        hint="Seleccione la caja en la que se efectuo la transaccion" :error-messages="errors" :success="valid">
                                        <template v-slot:no-data>
                                            <v-list-item>
                                                <v-list-item-title>No hay cajas abierta<strong> para la sucursal que pertenece.</strong></v-list-item-title>
                                            </v-list-item>
                                        </template>
                                        <template v-slot:item="{ item }">
                                            Usuario: <strong> {{ item.user }}</strong> {{ item.sucursal }} - {{ item.opened }}.
                                        </template>
                                        <template v-slot:selection="{ attr, on, item, selected }">
                                                <v-chip v-bind="attr" :input-value="selected" color="blue darken-3"
                                                class="white--text font-weight-normal rounded p-0" v-on="on">
                                                    <span v-text="' Usuario: ' +item.user + ' - '+ item.sucursal + ' - ' +item.opened"></span>
                                                </v-chip>
                                            </template>
                                        </v-autocomplete>
                                    </ValidationProvider>
                                </v-col>
                            </v-row>
                            <div class="row">
                                <v-col cols="12" sm="6" md="4">
                                    <ValidationProvider rules="required" v-slot="{ errors, valid}" :immediate="true">
                                        <v-autocomplete :items="paymentMethod" item-text="name" item-value="value"
                                            v-model="transaction.paymentMethod" label="Metodo de pago*" prepend-icon="fas fa-credit-card"
                                            :error-messages="errors" :success="valid">
                                        </v-autocomplete>
                                    </ValidationProvider>
                                </v-col>
                                <v-col cols="12" sm="6" md="4">
                                    <template>
                                        <v-file-input v-model="fileEvidences" color="deep-green accent-4" counter
                                            :show-size="true" :rules="fileRules"
                                            label="Adjuntar fichero" :multiple="false" placeholder="Seleccionar archivo..." prepend-icon="fas fa-paperclip">
                                                <template v-slot:selection="{ index, text }">
                                                    <v-chip v-if="index < 2" color="deep-purple accent-4" dark label small >
                                                        {{ text }}
                                                    </v-chip>
                                                    <span v-else-if="index === 2" class="text-overline grey--text text--darken-3 mx-2">
                                                        +{{ files.length - 2 }} Archivos
                                                    </span>
                                                </template>
                                        </v-file-input>
                                    </template>
                                </v-col>
                            </div>
                            <v-row>
                                <v-col cols="12" sm="12" md="12">
                                    <v-textarea class="mx-2" label="Descripcion adicional" rows="3" hint="Descripcion/nota adicional.."
                                    prepend-icon="fas fa-comment" persistent-hint clearable v-model="transaction.description"></v-textarea>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-form>
                </ValidationObserver>
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
import SingleCalendar from '@/components/shared/SingleCalendar.vue';
import TransactionFileService from '@/service/transactions/TransactionFileService';
import TransactionRecordService from '@/service/transactions/TransactionRecordService';
import { getToday } from '@/utils/DateUtil';

export default {
    name: 'Formtransaction',
    props: {
        transactionId: { type: Number, default: null },
        dialog: { type: Boolean, default: false },
        transactionType: { type: Array, default: () => [] },
        transactionCategory: { type: Array, default: () => [] },
        paymentMethod: { type: Array, default: () => [] },
        lstCashRegister: { type: Array, default: () => [] },
        hasCashRegisterOpened: { type: Boolean, default: false },
        handleDialog: { type: Function },
        initialize: { type: Function },
    },
    components: { SingleCalendar },
    beforeMount() {
        if( this.transactionId ){
          this.show(this.transactionId);  
        }
    },
    destroyed() {
        this.$emit('update:transactionId', null);
    },
    data() {
        return {
            transaction: {
                id:             null,
                amount:         0,
                registerDate:  getToday().toFormat('yyyy-MM-dd'),
                description:    '',
                paymentMethod:  'EFECTIVO',
                transactionCategoryId: null,
                transactionType: 'INCOME',
                cashRegisterId: null,
                isCashRegister: false,
                deleted: false
            },
            fileEvidences: undefined,
            fileRules: [
                value => !value || value.size < 2000000 || 'El archivo debe ser menor a 2 MB!'
            ],
        }
    },
    methods: {
        resetData(){
            this.handleDialog();
            this.transaction = { id: null, amount: 0, registerDate: getToday().toFormat('yyyy-MM-dd'), description: '', paymentMethod: 'EFECTIVO', transactionCategoryId: null, transactionType: 'INCOME', cashRegisterId: null, isCashRegister: false };
            this.$refs.obstransaction.reset();
        },
        async uploadEvidence(transactionId){
            try {
                const rsp = await TransactionFileService.uploadEvidence(this.fileEvidences, transactionId);
                this.initialize();
                this.handleDialog();
                this.fileEvidences = undefined;
                this.$swal.fire({ icon: 'success', title: 'Transaccion registrada', text: rsp.data.message, showConfirmButton: false, timer: 3500 });
                
            } catch (error) {
                this.handleDialog();
                this.initialize();
                this.fileEvidences = undefined;
                this.$swal.fire({ icon: 'error', title: 'Transaccion registrada', text: "Ocurrio un error al subir la evidencia", showConfirmButton: false, timer: 3500 });
            }
        },
        async saveTransaction(){
            this.$refs.obstransaction.validate().then( async isValid => {
                if( isValid ){
                    try {
                        const response = await TransactionRecordService.create( this.transaction );
                        if(response.status === 200 || response.status === 201){
                            if(this.fileEvidences != undefined){
                                await this.uploadEvidence(response.data.id);
                            } else {
                                this.initialize();
                                this.handleDialog();
                                this.fileEvidences = undefined;
                                this.$swal.fire({ icon: 'success', title: 'Transaccion registrada', text: 'Transaccion registrada correctamente!!', showConfirmButton: false, timer: 4000 });
                            }
                        }
                    } catch (error) {
                        this.$swal.fire({ icon: 'error', title: 'Transaccion no registrada', text: error.message, showConfirmButton: false, timer: 4000 });
                    }
                }
            }).catch( err  => {
                this.$swal.fire({ position: 'top-end', icon: 'error', title: 'Error', text: 'No se pudo guardar los datos, ' +err.message, showConfirmButton: false, timer: 4000 });
            })
        },
        show( transactionId ) {
            TransactionRecordService.show( transactionId ).then( response => {
                this.transaction = response.data;
            }).catch( err => {
                this.$swal.fire({ position: 'top-end', icon: 'error', title: 'Error', text: err.response.data, showConfirmButton: false, timer: 4000 });
            })
        },
        update() {
            this.$refs.obstransaction.validate().then( isValid => {
                if( isValid ){
                    TransactionRecordService.update( this.transaction ).then( rsp => {
                        if (rsp.status === 200) {
                            this.resetData();
                            this.initialize();
                            this.$swal.fire({ position: 'top-end', icon: 'success', title: 'Actualización', text: 'Categoria actualizada', showConfirmButton: false, timer: 4000 });
                        }
                    }).catch( err => {
                        this.$swal.fire({ position: 'top-end', icon: 'error', title: 'Error', text: err.response.data, showConfirmButton: false, timer: 4000 });
                    })
                }
            }).catch( err  => {
                this.$swal.fire({ position: 'top-end', icon: 'error', title: 'Error', text: 'No se pudo actualizar los datos, ' +err.message, showConfirmButton: false, timer: 4000 });
            })
        }
    },
    watch: {
        transactionId: function( id ){
            console.log({id}, 'entra');
            if(id != null && id > 0){
                this.show( id );
            }
        }
    },
    computed: {
        title() {
            return this.transactionId ? 'Editar transaccion' : 'Nueva transaccion';
        }
    }
}
</script>