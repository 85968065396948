<template>
    <div>
        <v-btn icon small color="blue darken-3 p-1" class="mr-2" @click.prevent="editTransaction(item)" v-if="!item.deleted">
            <v-icon>fas fa-edit</v-icon>
        </v-btn>
        <v-btn icon small color="green p-1" @click.prevent="showEvidencesTransaction(item)">
            <v-icon>fas fa-images</v-icon>
        </v-btn>
        <v-btn icon small color="red p-1" @click.prevent="removeTransaction(item)" v-if="!item.deleted">
            <v-icon>fas fa-ban</v-icon>
        </v-btn>
        <v-btn class="ma-2" color="blue darken-4" icon small @click.prevent="previewPDF({ isWithPrinter : true })">
            <v-icon>fas fa-print</v-icon>
        </v-btn>
        <v-btn class="ma-2" color="gray darken-4" icon small @click.prevent="previewPDF({ isWithPrinter : false })">
            <v-icon>fas fa-file-pdf</v-icon>
        </v-btn>

        <ViewFilesTable :lstFiles="lstEvidences" :dialog="dialogEvidences" title-files="Ficheros y evidencias de transacciones"
        :handleDialogFiles="handleDialogFiles"  v-if="dialogEvidences">
        </ViewFilesTable>
        <PreviewPDF :pdfSource="pdfSource" :pdfBlob="pdfBlob"
            title-pdf="INGRESO/EGREGO" :handlePdfModal="handlePdfModal" :dialog="showPdf" v-if="showPdf">
        </PreviewPDF>
    </div>

</template>
<script>
import PreviewPDF from '@/components/shared/PreviewPDF.vue';
import ViewFilesTable from '@/components/shared/ViewFilesTable.vue';
import TransactionFileService from '@/service/transactions/TransactionFileService';
import TransactionRecordService from '@/service/transactions/TransactionRecordService';

export default {
    name: 'ActionTransaction',
    props: {
        item: { type: Object, required: true },
        editTransaction: { type: Function },
        initializeTransaction: { type: Function },
    },
    components: { ViewFilesTable, PreviewPDF },
    data() {
        return {
            openDialogSale: false,
            // to evidences
            lstEvidences: [],
            dialogEvidences: false,
            showPdf: false,
            pdfSource: '',
            pdfBlob: null
        }
    },
    methods: {
        showEvidencesTransaction(item){
            this.dialogEvidences = true;
            this.getEvidencesTransaction(item);
        },
        getEvidencesTransaction({ id: ventaId }) {
            let params = { page: 0, size: 25 };
            this.lstEvidences = [];

            TransactionFileService.table( params, ventaId ).then( response => {
                let evidences = response.data.rows.map(evidence => {
                    return {
                        ...evidence,
                        typeFile: evidence.type.startsWith('image') ? 'image' : 'pdf',
                        url: evidence.name != null ? `${this.$store.state.auth.rutaFichero}transactionEvidences/${evidence.name}` : Camera
                    };
                });
                this.lstEvidences = evidences;
            }).catch(err => {
                this.lstEvidences = [];
                this.$swal.fire({ position: 'top-end', icon: 'error', html: 'Error al cargar las evidencias' + err.response.data, showConfirmButton: false, timer: 3500 });
            })
        },
        handleDialogFiles() { this.dialogEvidences = !this.dialogEvidences; },
        removeTransaction( item ){
            this.$swal.fire({
                title: '¿Estás seguro?',
                text: '¡No podrás revertir esto!',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Sí, eliminar',
                cancelButtonText: 'Cancelar'
            }).then((result) => {
                if (result.value) {
                    TransactionRecordService.delete(item.id).then( resp => {
                        this.initTableTransaction();
                        this.$swal.fire({ icon: 'success', title: '¡Hecho!', text: 'Se eliminó correctamente', footer: 'Sucursa desactivada', timer: 3500 });
                    }).catch( err => {
                        this.$swal.fire({ icon: 'error', title: 'Oops...', text: 'Algo salió mal!' + err.response.data, footer: 'Intenta de nuevo' });
                    });
                }
            });
        },
        previewPDF({ isWithPrinter = false }) {
            const params = { isWithPrinter }
            TransactionRecordService.printTransaction(this.item.id, params ).then( response => {
                this.pdfBlob = new Blob([response.data], { type: 'application/pdf' })
                this.pdfSource = URL.createObjectURL(this.pdfBlob)
                this.showPdf = true
            }).catch( error => {
                this.$swal.fire({ icon: 'error', html: 'Error al generar el reporte' + error.response.data, showConfirmButton: false, timer: 3500 });
            });
        },
        handlePdfModal(){
            this.showPdf = !this.showPdf
            this.cleanupPdf()
        },
        cleanupPdf(){
            if(this.pdfSource){
                URL.revokeObjectURL(this.pdfSource)
                this.pdfSource = null
            }
            this.pdfBlob = null
        }
    }
}
</script>