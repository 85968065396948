import { http, authHeader } from '@/http-commons';

class TransactionRecordService {

    create( data ){
        return http.post('api/v1/transactionRecord/create', data, { headers : authHeader() });
    }
    show( transactionId ){
        return http.get(`api/v1/transactionRecord/findOne/${transactionId}`, { headers : authHeader() });
    }
    findAll(){
        return http.get("api/v1/transactionRecord/all", {headers: authHeader() });
    }
    table( params ){
        return http.get('api/v1/transactionRecord/table', { params, headers : authHeader() });
    }
    update( data ){
        return http.put(`api/v1/transactionRecord/update/${data.id}`, data, { headers : authHeader() });
    }
    delete( transactionId ){
        return http.delete(`api/v1/transactionRecord/delete/${transactionId}`, { headers : authHeader() });
    }
    printTransaction(transactionId, params){
        return http.get(`/api/v1/transactionRecord/printTransaction/${transactionId}`, { params, headers: authHeader(), responseType: 'blob' });
    }
}

export default new TransactionRecordService();