<template>
    <v-row align="center" justify="center">
        <v-col cols="12" sm="12" md="12">
            <v-card class="elevation-4">
                <v-card-title>
                    <v-toolbar flat>
                        <v-btn small dark color="primary" @click="dialog = true">
                            <v-icon left>fas fa-plus-square</v-icon>Nueva transaccion
                        </v-btn>
                        <v-divider class="mx-4" inset vertical></v-divider>
                        <v-spacer></v-spacer>
                    </v-toolbar>
                    <v-col cols="12" md="8" sm="4" class="d-flex justify-end">
                        <v-text-field v-model="search" append-icon="fas fa-search" label="Buscar transaccion" single-inline hide-details
                        @keydown.enter="searchTransaction" placeholder="Buscar por folio, descripcion">
                        </v-text-field>
                        <v-btn icon class="mt-4 ml-2">
                            <v-icon dense>fas fa-sync</v-icon>
                        </v-btn>
                        <Columns :headers.sync="headers"></Columns>
                    </v-col>
                </v-card-title>
                <v-data-table dense :headers="filteredHeaders" :items="transactions" class="elevation-1" fixed-header color="primary"
                :loading="loading" :options.sync="options" :server-items-length="totalTransaction"
                :footer-props="{ 'items-per-page-options': [ 25, 50, 100 ] }">
                    <template v-slot:[`item.actions`]="{ item }">
                        <ActionTransaction :item="item" :edit-transaction="editTransaction" :initialize-transaction.sync="initTableTransaction" />
                    </template>
                    <template v-slot:[`item.deleted`]="{ item }">
                        <v-chip small x-small dark v-text="!item.deleted ? 'Activo' : 'Inactivo'" :color="!item.deleted ? 'success' : 'error'">
                        </v-chip>
                    </template>
                    <template v-slot:[`item.isCashRegister`]="{ item }">
                        <v-chip small label x-small dark
                        v-text="!item.isCashRegister ? 'No Efectuado' : 'Si'">
                        </v-chip>
                    </template>
                </v-data-table>
            </v-card>
        </v-col>
        <FormTransaction :transaction-id.sync="transactionId" :initialize.sync="initTableTransaction"
        :transaction-type="transactionType" :transaction-category="transactionCategory" :payment-method="paymentMethod"
        :lstCashRegister="lstCashRegister" :has-cash-register-opened="hasCashRegisterOpened"
        :handleDialog="handleDialog" :dialog.sync="dialog" v-if="dialog" />
    </v-row>
</template>
<script>
import { getParamsPagination } from '@/utils/pagination';
import Columns from '@/components/shared/Columns.vue';
import FormTransaction from './FormTransaction.vue';
import TransactionRecordService from '@/service/transactions/TransactionRecordService';
import TransactionCategoryService from '@/service/transactions/TransactionCategoryService';
import CashRegisterDataService from '@/service/cashRegister/CashRegisterDataService';
import sucursalDataService from '@/service/catalogo/sucursal/sucursalDataService';
import { getToday } from '@/utils/DateUtil';
import ActionTransaction from './ActionTransaction.vue';
export default {
    name: 'ListaTransaction',
    components: { Columns, FormTransaction, ActionTransaction },
    created() {
        this.initTableTransaction();
        this.getTransactionCategory();
        this.findCashRegisterOpenedBySucursal(this.getSucursalId);
    },
    data() {
        return {
            transactionType: [
                { text: 'Ingreso', value: 'INCOME' },
                { text: 'Egreso', value: 'EXPENSE' }
            ],
            lstCashRegister: [],
            paymentMethod: [ 'EFECTIVO', 'TRANSFERENCIA', 'CHEQUE'],
            transactionCategory: [],
            transactions: [],
            options: {},
            totalTransaction: 0,
            search: '',
            headers:[
                { text: 'Acciones', value: 'actions', sortable: false, groupable: false, show: true },
                { text: 'ID', align: " d-none", value: 'id', visible: false, sortable: false, groupable: false, show: false },
                { text: 'Tipo transaccion', value: 'transactionTypeNatural', sortable: false, groupable: false, show: true },
                { text: 'Categoria', value: 'transactionCategoryName', sortable: false, groupable: false, show: true },
                { text: 'Fecha registro', value: 'registerDateNatural', sortable: false, groupable: false, show: true },
                { text: 'Descripcion', value: 'description', sortable: false, groupable: false, show: true },
                { text: 'Monto', value: 'amount', sortable: false, groupable: false, show: true },
                { text: 'Caja', value: 'isCashRegister', sortable: false, groupable: false, show: true },
                { text: 'Estatus', value: 'deleted', sortable: false, groupable: false, show: true },
            ],
            transactionId: null,
            loading: false,
            dialog: false,
        }
    },
    methods:{
        searchTransaction(){
            this.options.page = 1;
            this.initTableTransaction( this.options );
        },
        initTableTransaction( options = {} ){
            this.loading = true;
            const params = getParamsPagination({ options, search: this.search });
            TransactionRecordService.table( params ).then( response => {
                let { rows, totalElements } = response.data;
                this.transactions = rows;
                this.totalTransaction = totalElements;
            }).catch( err => {
                this.$swal.fire({ position: 'top-end', icon: 'error', title: 'Error al obtener los datos', text: err.response.data, showConfirmButton: false, timer: 3500 });
            }).finally(() => {
                this.loading = false;
            })
        },
        async getTransactionCategory(){
            this.transactionCategory = await TransactionCategoryService.findAll().then( resp => resp.data )
        },
        editTransaction({id}){
            this.transactionId = id;
            this.dialog = true;
        },

        handleDialog(){
            this.dialog = !this.dialog;
            this.transactionId = null;
        },
        findCashRegisterOpenedBySucursal(sucursalId) {
            CashRegisterDataService.findCashRegisterBySucursal(sucursalId).then(response => {
                this.lstCashRegister = response.data;
            })
        },
    },
    watch: {
        options: {
            handler( val, oldVal ) {
                if (val.page !== oldVal.page || val.itemsPerPage !== oldVal.itemsPerPage) {
                    this.initTableTransaction(val);
                }
            }, deep: true
        }
    },
    computed: {
        filteredHeaders() { return this.headers.filter(h => h.show); },
          // To validate if the user has cash register opened at least one.
        getSucursalId() { return sucursalDataService.getCurrentSucursalId(); },
        hasCashRegisterOpened() {
            if(this.lstCashRegister.length === 0) return false;
            const today = getToday().toFormat('dd/MM/yyyy');
            return this.lstCashRegister.filter(cashRegister => cashRegister.status === 'OPENED' && cashRegister.opened === today).length > 0;
        },
        getCashRegisterOpenedByUser() {
            if(this.lstCashRegister.length === 0) return [];
            const today = getToday().toFormat('dd/MM/yyyy');
            return this.lstCashRegister.filter(cashRegister => cashRegister.status === 'OPENED' && cashRegister.opened !== today);
        }
    }
}
</script>