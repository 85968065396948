import { http, authHeader } from '@/http-commons';

class TransactionFileService {

    uploadEvidence(evidence, transactionId) {
        const formData = new FormData();
        formData.append('evidence', evidence);
        return http.post(`/api/v1/transactionFile/uploadEvidence/${transactionId}`, formData, { headers: authHeader() });
    }

    table( params, transactionId ) {
        return http.get(`api/v1/transactionFile/table/${transactionId}`, { params, headers : authHeader() });
    }

}

export default new TransactionFileService()